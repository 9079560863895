import { getCookie, setCookie } from "../../../helper/cookie-storage";
import { isProd } from "../../../utilities/enviroments";

export enum OneTrustCookie {
  StrictlyNecessary = "C0001",
  Performance = "C0002",
  Functional = "C0003",
  Targeting = "C0004",
}

export const ONE_TRUST_CUSTOM_COOKIE_NAME = "optanoncustomconsentprovider";

export function insertOneTrustBanner(src: string, dataDomain: string) {
  const head = document.querySelector("head");

  const script = document.createElement("script");
  script.setAttribute("data-domain-script", dataDomain);
  script.setAttribute("data-document-language", isProd() ? "true" : "false");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("charset", "UTF-8");
  script.setAttribute("id", "_oneTrustCookieBannerScriptId");
  script.setAttribute("src", src);

  const script2 = document.createElement("script");
  script2.setAttribute("type", "text/javascript");
  script2.text = `function OptanonWrapper() { window.OneTrust.OnConsentChanged((e) => window.setOptanoncustomconsentprovider(e)); }`;

  (window as any).setOptanoncustomconsentprovider = setOptanoncustomconsentprovider;

  // Ensure the script is the first one in the head
  if (head?.firstChild) {
    head.insertBefore(script2, head.firstChild);
    head.insertBefore(script, head.firstChild);
  } else {
    head?.appendChild(script);
    head?.appendChild(script2);
  }
}

function setOptanoncustomconsentprovider(e: any) {
  const accpetedCookies = (e?.detail as string[])?.join(",");
  if (!accpetedCookies) return;
  const encodedAccpetedCookies = btoa(accpetedCookies);
  if (!encodedAccpetedCookies) return;
  setCookie(ONE_TRUST_CUSTOM_COOKIE_NAME, encodedAccpetedCookies);
  // Reload the page to trigger all the tracking events that depends on this one
  window.location.reload();
}

export function requiredCookiesAccepted(requiredCookies: OneTrustCookie[]): boolean {
  const cookie = getCookie(ONE_TRUST_CUSTOM_COOKIE_NAME);
  if (!cookie) return false;

  // Decode the OptanonConsent value
  const decodedConsentValue = atob(cookie);

  // Check if all required cookies are included
  return requiredCookies.every((cookie) => decodedConsentValue.includes(cookie));
}

import React, { ReactElement, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import store from "../../../redux/store";

import { getCurrentRoute, getLandingPageURL, getRoutePath, NavItem } from "../../../utilities/navigation";

import { getTenantRole, isSuperAdmin } from "../../../helper/role-helper";
import { getFeatureConfigurationIsEnabled } from "../../../helper/feature-configuration-helper";
import { checkSidebarOptionVisibility } from "../../../helper/feature-permissions-helper";
import { CURRENT_LOCALE, getLocaleImage, toLiteral } from "../../../helper/locale-utils";

import { SIDEBAR_LIST } from "./internal-sidebar-list";
import { ADMIN_SECTION, CANDIDATE_SOURCING_SECTION, HOME_SECTION, RECRUITMENT_CONNECT_SECTION, ROLE_ADMIN } from "../../../constants";

import Sidebar, { SidebarButton, SidebarDivider, SidebarLogo } from "@adeccoux/tag-ds/sidebar";

import ContextConfiguration from "../components/context-configuration/context-configuration";

import "./internal-sidebar.scss";

import SidebarFooter from "./internal-sidebar-footer";

import { getMainLogo } from "../../../helper/brand-helper";
import { generateShowNameBranch } from "../../../helper/app-helper";
import { getTagDSTheme } from "../../../utilities/theme";
import { useDevice } from "../../../hooks/useDevice";

/**
 * Component that renders a sidebar with navigation opstions
 */
const InternalSidebar: React.FC<any> = (): ReactElement => {
  const history = useHistory();

  const currentRoute: NavItem = getCurrentRoute(window.location.pathname);
  const themesWithoutLogo = ["office-angels-theme"];
  const theme = themesWithoutLogo.find((_theme) => _theme === store?.getState().tenant?.selectedTenant?.theme) ? "adecco-theme" : store?.getState()?.tenant?.selectedTenant?.theme;

  const tenatStore = store?.getState()?.tenant;
  const tenants = tenatStore?.tenants;
  const tenant = tenatStore?.selectedTenant;
  // if visitor is comming using external token we hide menu
  const external = store?.getState()?.auth?.external || null;
  const tenantIndex = tenants?.findIndex((_tenant: any) => _tenant.alias === tenant?.alias);

  const role = getTenantRole(tenants, tenant?.alias);
  const isSuperAdminOrAdmin = isSuperAdmin() || role === ROLE_ADMIN;

  const recruiterPortalEnabled = getFeatureConfigurationIsEnabled("General Settings", "recruiter-portal");
  const proactiveSourcingEnabled = getFeatureConfigurationIsEnabled("Candidate Sourcing Settings", "proactive-sourcing-tab");
  const jobPostingEnabled = getFeatureConfigurationIsEnabled("Candidate Sourcing Settings", "job-posting-tab");
  const leadsManagementEnabled = getFeatureConfigurationIsEnabled("Candidate Sourcing Settings", "leads-management-sourcing-tab");
  const standaloneTemplatesEnabled = getFeatureConfigurationIsEnabled("Candidate Sourcing Settings", "standalone-jd-generator");

  const [selected, setSelected] = useState(selectedButton());
  const [openConfiguration, setOpenConfiguration] = useState(false);
  const pirateFlag = store.getState()?.tenant?.userProperties?.pirateFlag === true;

  // Responsive control
  const { isTablet, isMobile, isDesktop } = useDevice();
  const [collapsed, setCollapsed] = useState(!isDesktop);
  const [showSortlistOptions, setShowSortlistOptions] = useState(false);

  useEffect(() => {
    setCollapsed(!isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet, isMobile, isDesktop]);

  useEffect(() => {
    setSelected(selectedButton());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute?.id, tenantIndex]);

  function selectedButton() {
    switch (currentRoute?.section) {
      case HOME_SECTION:
        return "RECRUITER_PORTAL";
      case RECRUITMENT_CONNECT_SECTION:
        return "RECRUITMENT";
      case CANDIDATE_SOURCING_SECTION:
        return "SOURCING";
      case ADMIN_SECTION:
        return "ADMINISTRATOR_HOME";
      default:
        return "DEVELOPMENT_GUIDELINE";
    }
  }

  function getBranchName() {
    const branch: any = tenant?.branches?.find((currentBranch: any) => currentBranch?.branchId === tenant?.branchId || currentBranch?.id === tenant?.branchId);
    if (branch) {
      const branchLiteral = toLiteral({ id: "admin.manage.tenant.branch" });
      const costCenter = branch.costCenter;
      let branchName = branch.branchName || branch.name;
      if (costCenter) branchName = generateShowNameBranch(branchName, costCenter);
      return branchName ? `${branchLiteral} - ${branchName}` : "";
    }
    return "";
  }

  const renderSourcingOptions = () => {
    let _options = [];
    _options.push({ id: "JOB_LISTING", title: "sidebar.sourcing.2" });
    if (proactiveSourcingEnabled) _options.push({ id: "PROACTIVE_SOURCING", title: "sidebar.proactive.sourcing" });
    if (jobPostingEnabled) _options.push({ id: "JOB_POSTING_LISTING", title: "sidebar.job.posting" });
    if (leadsManagementEnabled) _options.push({ id: "LEADS_MANAGEMENT", title: "Leads Management" });
    if (standaloneTemplatesEnabled) _options.push({ id: "JOB_DESCRIPTION_GENERATOR", title: "sidebar.jd.generator" });
    return _options?.map((option) => (
      <Link
        to={getRoutePath(option?.id).replace(":id", `${option?.id}`)}
        key={option?.id}
        id={option?.id}
        className={`dropdown-item medium ${currentRoute && currentRoute.id === option?.id ? "selected" : ""}`}
        onClick={() => setSelected("SOURCING")}
      >
        {toLiteral({ id: option?.title })}
      </Link>
    ));
  };

  const clickOpenConfiguration = () => setOpenConfiguration(true);

  function goTo(buttonId: string) {
    if (isMobile && buttonId !== "SOURCING") setCollapsed(true);
    if (buttonId === "PROACTIVE_SOURCING" || buttonId === "JOB_POSTING_LISTING" || buttonId === "LEADS_MANAGEMENT") setShowSortlistOptions(false);
    if (buttonId !== "SOURCING" && buttonId !== "OPTIONS") history.push(`${getRoutePath(buttonId)}`);
  }

  const renderSidebar = (_defaultCollapsed: boolean) => {
    return external ? (
      <></>
    ) : (
      <nav id="app-sidebar" aria-label="sidebar" className={`${_defaultCollapsed ? "rc-sidebar_collapsed" : "rc-sidebar"} ${isMobile ? "mobile_sidebar" : ""}`}>
        <Sidebar
          collapsed={_defaultCollapsed}
          className={`tag-ds ${getTagDSTheme(tenant.theme)}`}
          defaultItemSelected={selected}
          onClickSidebarItem={goTo}
          onCollapse={() => {
            setCollapsed(!collapsed);
            setShowSortlistOptions(false);
          }}
          role="nav"
        >
          <SidebarLogo key="logo">
            <img src={getMainLogo(theme, true)} className="main_logo" alt={theme} onClick={() => history.push(getLandingPageURL() || getRoutePath(currentRoute?.id))} />
          </SidebarLogo>
          <SidebarDivider key="div1"></SidebarDivider>
          {recruiterPortalEnabled && (
            <SidebarButton
              key="recruiterPortal"
              id="RECRUITER_PORTAL"
              href={getRoutePath("RECRUITER_PORTAL")}
              icon={<span className="material-icons outlined">home</span>}
              label={toLiteral({ id: "sidebar.home" })}
              title={toLiteral({ id: "sidebar.home" })}
            />
          )}
          {checkSidebarOptionVisibility(SIDEBAR_LIST[1]) && (
            <SidebarButton
              key="recruitment"
              id="RECRUITMENT"
              icon={<span className="material-icons outlined">receipt</span>}
              label={toLiteral({ id: "sidebar.recruitment" })}
              href={getRoutePath("RECRUITMENT")}
              title={toLiteral({ id: "sidebar.recruitment" })}
            />
          )}
          {checkSidebarOptionVisibility(SIDEBAR_LIST[2]) && (
            <React.Fragment>
              {!isMobile || _defaultCollapsed ? (
                <SidebarButton
                  dropdown
                  key="candidateSourcing"
                  id="SOURCING"
                  icon={<span className="material-icons outlined">document_scanner</span>}
                  label={toLiteral({ id: "sidebar.sourcing" })}
                  title={toLiteral({ id: "sidebar.sourcing" })}
                >
                  {(!isMobile || _defaultCollapsed) && renderSourcingOptions()}
                </SidebarButton>
              ) : (
                <SidebarButton
                  key="candidateSourcing"
                  id="SOURCING"
                  icon={<span className="material-icons outlined">document_scanner</span>}
                  label={toLiteral({ id: "sidebar.sourcing" })}
                  title={toLiteral({ id: "sidebar.sourcing" })}
                  onClick={() => setShowSortlistOptions(!showSortlistOptions)}
                />
              )}
            </React.Fragment>
          )}
          {showSortlistOptions && (
            <React.Fragment>
              <SidebarButton
                key="candidateSourcing"
                id="JOB_LISTING"
                href={getRoutePath("JOB_LISTING")}
                icon={<></>}
                className="ml2"
                label={toLiteral({ id: "sidebar.sourcing.2" })}
                title={toLiteral({ id: "sidebar.sourcing.2" })}
              />
              {proactiveSourcingEnabled && (
                <SidebarButton
                  key="candidateSourcing"
                  id="PROACTIVE_SOURCING"
                  href={getRoutePath("PROACTIVE_SOURCING")}
                  icon={<></>}
                  className="ml2"
                  label={toLiteral({ id: "sidebar.proactive.sourcing" })}
                  title={toLiteral({ id: "sidebar.proactive.sourcing" })}
                />
              )}
              {jobPostingEnabled && (
                <SidebarButton
                  key="candidateSourcing"
                  id="JOB_POSTING_LISTING"
                  href={getRoutePath("JOB_POSTING_LISTING")}
                  icon={<></>}
                  className="ml2"
                  label={toLiteral({ id: "sidebar.job.posting" })}
                  title={toLiteral({ id: "sidebar.job.posting" })}
                />
              )}
              {standaloneTemplatesEnabled && (
                <SidebarButton
                  key="candidateSourcing"
                  id="STANDALONE_TEMPLATES"
                  href={getRoutePath("JOB_DESCRIPTION_GENERATOR")}
                  icon={<></>}
                  className="ml2"
                  label={toLiteral({ id: "sidebar.jd.generator" })}
                  title={toLiteral({ id: "sidebar.jd.generator" })}
                />
              )}
              {leadsManagementEnabled && (
                <SidebarButton
                  key="candidateSourcing"
                  id="LEADS_MANAGEMENT"
                  href={getRoutePath("LEADS_MANAGEMENT")}
                  icon={<></>}
                  className="ml2"
                  label={toLiteral({ id: "Leads Management" })}
                  title={toLiteral({ id: "Leads Management" })}
                />
              )}
            </React.Fragment>
          )}
          {isSuperAdminOrAdmin && (
            <SidebarButton
              key="adminPanel"
              id="ADMINISTRATOR_HOME"
              href={getRoutePath("ADMINISTRATOR_HOME")}
              icon={<span className="material-icons outlined">settings</span>}
              label={toLiteral({ id: "sidebar.admin.settings" })}
              title={toLiteral({ id: "sidebar.admin.settings" })}
            />
          )}
          {pirateFlag && (
            <SidebarButton
              key="development_guideline"
              id="DEVELOPMENT_GUIDELINE"
              icon={<span className="material-icons outlined">integration_instructions</span>}
              label={toLiteral({ id: "Development Guideline" })}
              title={toLiteral({ id: "Development Guideline" })}
              href={getRoutePath("DEVELOPMENT_GUIDELINE")}
            />
          )}
          <SidebarDivider key="div2">{toLiteral({ id: "tenant.configuration" })}</SidebarDivider>
          <SidebarButton
            key="options"
            id="OPTIONS"
            icon={<span className="material-icons outlined">switch_account</span>}
            label={toLiteral({ id: "sidebar.configuration" })}
            title={toLiteral({ id: "sidebar.configuration" })}
            onClick={clickOpenConfiguration}
          />
          {!_defaultCollapsed && (
            <SidebarFooter>
              <div className="small">
                {tenants?.[tenantIndex]?.name} {pirateFlag ? ` - ${tenant?.id}` : ""}
              </div>
              <div className="small">{getBranchName()}</div>
              {pirateFlag && (
                <div className="small">
                  {CURRENT_LOCALE}
                  <img src={getLocaleImage(CURRENT_LOCALE)} className="pirate-flag" alt=" " />
                </div>
              )}
            </SidebarFooter>
          )}
        </Sidebar>
      </nav>
    );
  };

  return (
    <React.Fragment>
      {collapsed && renderSidebar(true)}
      {!collapsed && renderSidebar(false)}
      {openConfiguration ? <ContextConfiguration setOpenConfiguration={setOpenConfiguration} /> : <></>}
    </React.Fragment>
  );
};

export default InternalSidebar;

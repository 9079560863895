import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../../../../redux/store";
import { refreshFeaturesAndProfileTenant, setLanguage, setUserProfile } from "../../../../redux/slices/tenantSlice";

import { getCurrentRoute, NavItem } from "../../../../utilities/navigation";

import { SelectedTenantObject } from "../../../../types";

import { getAllCurentTenantRoles } from "../../../../helper/role-helper";
import { generateShowNameBranch } from "../../../../helper/app-helper";
import { TenantStorage } from "../../../../helper/tenant-storage";
import { CURRENT_LOCALE, getLanguageString, getLocaleImage, getUserProfileName, hideLanguage, toLiteral } from "../../../../helper/locale-utils";
import { getFeatureConfigurationIsEnabled } from "../../../../helper/feature-configuration-helper";

import Modal, { ModalBody, ModalFooter, ModalHeader } from "@adeccoux/tag-ds/modal";
import Select, { Option } from "@adeccoux/tag-ds/select";

import "./context-configuration.scss";
import { SelectFilter, SelectFilterOption } from "../../../shared/forms";
import { addNotification, removeNotification } from "../../../shared/notifications/notifications-service";
import { getTagDSTheme } from "../../../../utilities/theme";

/**
 * Component that renders the internal page
 */
const ContextConfiguration: React.FC<any> = (props): ReactElement => {
  const { setOpenConfiguration } = props;

  const dispatch = useDispatch();
  const tenantStorage = new TenantStorage();
  const currentRoute: NavItem = getCurrentRoute(window.location.pathname);

  const tenatStore = store?.getState()?.tenant;
  const tenant = tenatStore?.selectedTenant;
  const tenants = tenatStore?.tenants;
  const selectedUserProfile = tenatStore?.selectedUserProfile;
  const allRoles = getAllCurentTenantRoles(tenants, tenant?.alias);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [confirmData, setConfirmData] = useState();
  const [dataType, setDataType] = useState<any>();
  const [fieldsDisabled, setFieldsDisabled] = useState(false);

  const [selectedTenant, setSelectedTenant] = useState<SelectedTenantObject>(tenant);
  const [_tenantAlias, _setTenantAlias] = useState(tenant?.alias);
  const [_branchIndex, _setBranchIndex] = useState(tenant?.branches?.findIndex((branch) => branch?.branchId === tenant?.branchId));
  const [_language, _setLanguage] = useState(CURRENT_LOCALE);
  const [_profile, _setProfile] = useState(selectedUserProfile || allRoles[0]);

  const [userProfilesEnabled, setUserProfilesEnabled] = useState(getFeatureConfigurationIsEnabled("General Settings", "users-profiles", selectedTenant?.selectedTenantConfiguration));
  const [branchessEnabled, setBranchessEnabled] = useState(getFeatureConfigurationIsEnabled("General Settings", "tenant-branches", selectedTenant?.selectedTenantConfiguration));
  const pirateFlag = store.getState()?.tenant?.userProperties?.pirateFlag === true;

  useEffect(() => {
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  useEffect(() => {
    setUserProfilesEnabled(getFeatureConfigurationIsEnabled("General Settings", "users-profiles", (selectedTenant as any)?.configuration));
    setBranchessEnabled(getFeatureConfigurationIsEnabled("General Settings", "tenant-branches", (selectedTenant as any)?.configuration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(selectedTenant as any)?.configuration]);

  function resetValues() {
    setSelectedTenant(tenant);
    _setTenantAlias(tenant?.alias);
    _setBranchIndex(tenant?.branches?.findIndex((branch) => branch?.branchId === tenant?.branchId));
    _setLanguage(CURRENT_LOCALE);
    _setProfile(selectedUserProfile || allRoles[0]);
  }

  function openModalFunction(title: string, body: string, type: string, data: any) {
    setModalTitle(title);
    setModalBody(body);
    setDataType(type);
    setConfirmData(data);
    setOpenConfirmationModal(true);
  }

  async function saveConfiguration() {
    if (!_language) {
      addNotification({ type: "error", content: toLiteral({ id: "Missing language to select" }), timer: 3 });
      return;
    }
    if (branchessEnabled && selectedTenant?.branches && _branchIndex < 0) {
      addNotification({ type: "error", content: toLiteral({ id: "Missing branch to select" }), timer: 3 });
      return;
    }
    addNotification({ type: "loading", content: toLiteral({ id: "admin.configuration.loading.change" }), timer: 3, id: "adminConfigLoading" });
    setFieldsDisabled(true);
    // Save tenant and branch selection
    await onTenantChangedClicked();
    // Save language
    dispatch(setLanguage(_language));
    // Save profile
    dispatch(setUserProfile(_profile));
    tenantStorage.setUserProfile(_profile);
    // Close modal
    setOpenConfiguration(false);
    setFieldsDisabled(false);
    removeNotification("adminConfigLoading");
  }

  async function onTenantChangedClicked() {
    const index = tenants?.findIndex((_tenant) => _tenant?.alias === _tenantAlias);
    const branch = selectedTenant?.branches?.[_branchIndex];
    const newTenant = { alias: _tenantAlias, index: index, regionId: branch?.regionId || "", branchId: branch?.branchId || "", zoneId: branch?.zoneId || "" };

    await refreshFeaturesAndProfileTenant(_tenantAlias, tenants, newTenant, index, dispatch);
  }

  function changeTenant(_tenantId: any, force?: boolean) {
    const tenantId = _tenantId?.target?.value || _tenantId;
    const newTenant = tenants?.find((_tenant) => _tenant?.id === parseInt(tenantId));
    if (!newTenant) return;
    if (!force && (currentRoute?.id === "CREATE_PROCESS" || currentRoute?.id === "CREATE_SERVICE_TEMPLATE")) openModalFunction("tenant.change", "creator.tenant.warning", "tenant", newTenant.alias);
    _setTenantAlias(newTenant?.alias);
    const branchId = newTenant?.branches?.[0]?.branchId;
    _setBranchIndex(0);
    const isLanguageValid = newTenant?.languages?.includes(_language);
    if (!isLanguageValid) _setLanguage(newTenant?.languages?.[0]);
    setSelectedTenant({ ...selectedTenant, ...newTenant, branchId });
  }

  function changeBranch(branchId: any, force?: boolean) {
    const __branchId = branchId?.target?.value || branchId;
    const branchIndex = selectedTenant?.branches?.findIndex((_branch) => _branch?.branchId === __branchId);
    if (!force && (currentRoute?.id === "CREATE_PROCESS" || currentRoute?.id === "CREATE_SERVICE_TEMPLATE")) openModalFunction("branch.change", "creator.branch.warning", "branch", __branchId);
    _setBranchIndex(branchIndex);
  }

  function changeLanguage(lang: any, force?: boolean) {
    if (!force && ["CREATE_PROCESS", "EDIT_PROCESS", "CREATE_SERVICE_TEMPLATE", "CREATE_SERVICE"].includes(currentRoute.id)) {
      openModalFunction("tenant.language", "creator.language.warning", "language", lang);
    }
    if (lang) _setLanguage(lang);
  }

  function confirmAction() {
    if (dataType === "tenant" && confirmData !== undefined) changeTenant(confirmData, true);
    else if (dataType === "language" && confirmData !== undefined) changeLanguage(confirmData, true);
    else if (dataType === "branch" && confirmData !== undefined) changeBranch(confirmData, true);
    setOpenConfirmationModal(false);
  }

  function changeProfile(profile: any) {
    _setProfile(profile);
  }

  function closeConfirmationModal() {
    resetValues();
    setOpenConfirmationModal(false);
  }

  return (
    <React.Fragment>
      <Modal
        id="context-configuration"
        renderAsPortal
        className={`contextConfigurationModal ${getTagDSTheme(selectedTenant?.theme)}`}
        open={true}
        onClose={() => !fieldsDisabled && setOpenConfiguration(false)}
      >
        <ModalHeader>{toLiteral({ id: "context.configuration.title" })}</ModalHeader>
        <ModalBody>
          <SelectFilter
            data-testid="tenant-select"
            disabled={tenants?.length <= 1 || fieldsDisabled}
            label={toLiteral({ id: "context.configuration.TENANT" })}
            placeholder={toLiteral({ id: "Select tenant" })}
            value={selectedTenant?.id?.toString()}
            onChange={changeTenant}
            name="tenant-select"
            filter
            required
            renderMenuAsPortal
          >
            {tenants?.map((_tenant) => (
              <SelectFilterOption key={_tenant?.alias} id={`${_tenant?.alias}`} value={`${_tenant?.id}`} label={_tenant?.name + (pirateFlag ? ` (${_tenant?.id} - ${_tenant?.alias})` : "")} />
            ))}
          </SelectFilter>
          {branchessEnabled && selectedTenant?.branches?.length !== 0 && (
            <SelectFilter
              data-testid="branch-select"
              disabled={selectedTenant?.branches?.length <= 1 || fieldsDisabled}
              label={toLiteral({ id: "context.configuration.BRANCH" })}
              placeholder={toLiteral({ id: "Select branch" })}
              value={selectedTenant?.branches?.find((branch) => branch?.branchId === selectedTenant?.branchId)?.branchId}
              onChange={changeBranch}
              name="branch-select"
              className="mt3"
              filter
              required
            >
              {selectedTenant?.branches?.map((_branch, index) => {
                return <SelectFilterOption key={index} id={`${index}`} value={_branch?.branchId} label={generateShowNameBranch(_branch?.branchName, _branch?.costCenter)} />;
              })}
            </SelectFilter>
          )}
          {selectedTenant?.languages?.length !== 0 && (
            <span className={"select-language-wrapper" + (pirateFlag ? " withFlag" : "")}>
              {pirateFlag ? <img src={getLocaleImage(_language)} className={"pirate-flag-language-selector"} /> : ""}
              <Select
                data-testid="language-select"
                disabled={selectedTenant?.languages?.length <= 1 || fieldsDisabled}
                label={toLiteral({ id: "context.configuration.LANGUAGE" })}
                placeholder={toLiteral({ id: "Select language" })}
                value={_language}
                onChange={changeLanguage}
                name="language-select"
                className="mt3"
                required
                renderMenuAsPortal
              >
                {selectedTenant?.languages?.map((language) => {
                  return (
                    <Option
                      key={language}
                      hidden={hideLanguage(language, { showToSuperAdmins: false, showToPirates: true })}
                      id={language}
                      data-testid={language}
                      value={language}
                      label={getLanguageString(language)}
                      className={"dropdown-item" + (pirateFlag ? " pirate-flag-background" : "")}
                      style={pirateFlag ? { backgroundImage: `url(${getLocaleImage(language)})` } : {}}
                    />
                  );
                })}
              </Select>
            </span>
          )}

          {userProfilesEnabled && allRoles?.length !== 0 && (
            <Select
              data-testid="profile-select"
              disabled={allRoles?.length <= 1 || fieldsDisabled}
              label={toLiteral({ id: "context.configuration.PROFILE" })}
              placeholder={toLiteral({ id: "Select Profile" })}
              value={_profile}
              onChange={changeProfile}
              name="profile-select"
              className="mt3"
              required
              renderMenuAsPortal
            >
              {[...new Set(allRoles)]?.map((_role) => {
                // Set is used to remove duplicate values
                return <Option key={_role} id={_role} value={_role} label={getUserProfileName(_role)} />;
              })}
            </Select>
          )}
        </ModalBody>
        <ModalFooter>
          <button data-testid="cancel-configuration" className="button-secondary small" onClick={() => setOpenConfiguration(false)} disabled={fieldsDisabled}>
            {toLiteral({ id: "CANCEL" })}
          </button>
          <button data-testid="save-configuration" className="small" onClick={() => saveConfiguration()} disabled={fieldsDisabled || !_language}>
            {toLiteral({ id: "context.configuration.save" })}
          </button>
        </ModalFooter>
      </Modal>
      {openConfirmationModal && (
        <Modal renderAsPortal className="tag-ds" open={true} onClose={() => closeConfirmationModal()}>
          <ModalHeader>
            <h4>{toLiteral({ id: modalTitle })}</h4>
          </ModalHeader>
          <ModalBody>{toLiteral({ id: modalBody })}</ModalBody>
          <ModalFooter>
            <button onClick={() => confirmAction()} disabled={fieldsDisabled}>
              Confirm
            </button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ContextConfiguration;

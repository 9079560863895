import { TokenObject } from "./token-storage-definitions";

const localStorageTokenKey = "rc_tokenStorage_tokenKey";

export class TokenStorage {
  setToken(token: TokenObject): void {
    localStorage.setItem(localStorageTokenKey, JSON.stringify(token));
  }

  getToken(): TokenObject | null {
    const data = localStorage.getItem(localStorageTokenKey);
    if (!data) return null;
    return JSON.parse(data);
  }

  removeToken(): void {
    localStorage.removeItem(localStorageTokenKey);
  }
}

import React from "react";
import ReactDom from "react-dom";
import moment from "moment";
import { required } from "./utilities/validations";
import { getDatesBetween2Dates } from "../calendar/calendar-service";
import { FormContext } from "./form";
import BaseInput from "./base-input";
import Calendar from "../calendar/calendar";

import { MODALS_ID_PARENT_CREATE } from "../../../constants";
export const datePickerMode = {
  SINGLE: "single",
  MULTIPLE: "mutiple",
  RANGE: "range",
};

export interface IProps {
  name?: string;
  type?: string;
  className?: string;
  value?: string;
  disabled?: boolean;
  label?: string;
  errors?: string[];
  validations?: Function[];
  validateOnChange?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  placeholder?: string;
  onChange?: Function;
  autoComplete?: string;
  [others: string]: any;
  //previous line avoids having to write specific component properties
  //rows?: number; //textarea only
  //cols?: number; //textarea only
}

export interface IState {
  errors?: string[];
  isValid?: boolean;
  value?: any;
  validateOnChange?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  dateFormat?: string;
  showCalendar?: boolean;
  calendarPosition?: any;
  parentFormOnChange?: Function;
}

class DateInputDeprecated extends BaseInput {
  state: Readonly<IState> = {
    errors: this.props.errors,
    isValid: undefined,
    value: this.props.value,
    validateOnChange: this.props.validateOnChange === false ? this.props.validateOnChange : true,
    disabled: this.props.disabled,
    readOnly: this.props.readOnly,
    loading: this.props.loading,
    dateFormat: this.props.dateFormat ? this.props.dateFormat : "YYYY-MMM-DD",
    showCalendar: false,
    calendarPosition: null,
  };

  constructor(props: any) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  inputRef: any = React.createRef();
  wrapperRef: any = null;

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("scroll", this.calculateCalendarPosition);
    window.addEventListener("resize", this.calculateCalendarPosition);
    window.addEventListener("orientationchange", this.calculateCalendarPosition);

    let value = null;
    if (this.props.defaultValue) {
      if (Object.prototype.toString.call(this.props.defaultValue) === "[object Date]") {
        value = this.props.defaultValue ? moment(this.props.defaultValue).format(this.state.dateFormat) : null;
      } else {
        value = this.props.defaultValue;
      }
    }

    this.setState({ value: value });
    if (this.props.customCalendar) {
      document.addEventListener("mousedown", this.handleClickOutside);
    }
    if (this.context && typeof this.context.addInputToContext === "function") {
      this.context.addInputToContext(this);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
    if (this.props.loading !== prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (this.props.disableRelated !== prevProps.disableRelated) {
      this.setState({ disableRelated: this.props.disableRelated });
    }
    if (this.props.requireRelated !== prevProps.requireRelated) {
      this.setState({ requireRelated: this.props.requireRelated });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("scroll", this.calculateCalendarPosition);
    window.removeEventListener("resize", this.calculateCalendarPosition);
    window.removeEventListener("orientationchange", this.calculateCalendarPosition);
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  calculateCalendarPosition = () => {
    const position = this.inputRef.current.getBoundingClientRect();
    const top = position.top + position.height; // - window.scrollY;
    const left = position.left;
    const calendarPosition: any = { top: top, left: left };
    if (left + 350 > window.outerWidth) {
      calendarPosition.right = "10px";
      calendarPosition.left = "auto";
    }

    const body = document.body,
      html = document.documentElement;
    const documentheight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (top + 350 > documentheight - window.scrollY) {
      calendarPosition.top = "auto";
      calendarPosition.bottom = "10px";
    }

    this.setState({ calendarPosition: calendarPosition });
  };

  renderCalendar = () => {
    const self = this;
    let activeDates: any = typeof this.state.value === "number" ? [moment(this.state.value)] : [moment(this.state.value, this.state.dateFormat)];
    let _value = this.state.value ? this.state.value : [];
    if (typeof _value === "string") _value = _value.split(",");
    if (_value && Object.prototype.toString.call(_value) === "[object Array]") {
      activeDates = [];
      _value.forEach(function (item: any) {
        activeDates.push(typeof item === "number" ? moment(item) : moment(item, self.state.dateFormat));
      });

      if (self.props.mode === datePickerMode.RANGE) {
        activeDates = getDatesBetween2Dates(activeDates[0], activeDates[1]);
      }
    }

    if (this.props.customCalendar) {
      if (this.state.showCalendar) {
        const calendar = (
          <div className="adecco-ui input-date-calendar-wrapper" style={this.state.calendarPosition} ref={this.setWrapperRef}>
            <Calendar
              disableFutureDates={this.props.disableFutureDates}
              disablePastDates={this.props.disablePastDates}
              activeDates={activeDates}
              disabledDates={this.props.disabledDates}
              onSelectDate={(date: Date) => this.selectCalendarDate(date)}
            />
          </div>
        );
        return ReactDom.createPortal(calendar, document.getElementById(MODALS_ID_PARENT_CREATE) as HTMLElement);
      }
    }
    return null;
  };

  toggleCalendar = () => {
    if (this.props.customCalendar) {
      this.calculateCalendarPosition();
      this.setState({ showCalendar: !this.state.showCalendar });
    }
  };

  handleClickOutside(event: any) {
    if (this.props.customCalendar) {
      if (this.state.showCalendar && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ showCalendar: false });
      }
    }
  }

  selectCalendarDate = (date: Date) => {
    if (this.props.customCalendar) {
      if (!this.props.mode || this.props.mode === datePickerMode.SINGLE) {
        let _value: string = "";

        _value = date ? moment(date).format(this.state.dateFormat) : "";

        this.setState({ showCalendar: false }, () => {
          this.processChange(_value, { target: this.inputRef.current });
        });
      }
      if (this.props.mode === datePickerMode.MULTIPLE || this.props.mode === datePickerMode.RANGE) {
        let _value = this.state.value ? this.state.value : [];
        if (typeof _value === "string") _value = _value.split(",");
        if (this.props.mode === datePickerMode.RANGE) {
          if (_value.length !== 2) {
            _value.push(moment(date).format(this.state.dateFormat));
          } else {
            _value = [];
            _value.push(moment(date).format(this.state.dateFormat));
          }
        }

        if (this.props.mode === datePickerMode.MULTIPLE) {
          if (_value.find((element: any) => element === moment(date).format(this.state.dateFormat))) {
            _value = _value.filter((element: any) => element !== moment(date).format(this.state.dateFormat));
          } else {
            _value.push(moment(date).format(this.state.dateFormat));
          }
        }

        this.setState({ showCalendar: true }, () => {
          this.processChange(_value, { target: this.inputRef.current });
        });
      }
    }
  };

  render() {
    let value = this.state.value ? this.state.value : "";

    if (Object.prototype.toString.call(value) === "[object Date]") {
      value = moment(this.props.defaultValue).format(this.state.dateFormat);
    }

    const inputType = this.props.customCalendar ? "text" : "date";
    let validationClass = "";
    if (typeof this.state.isValid !== "undefined" && this.state.isValid !== null) {
      if (!this.state.isValid || value !== "") {
        // Show only when error or is valid but not empty
        validationClass = this.state.isValid ? "valid" : "error";
      }
    }
    if (this.props.validations?.indexOf(required) !== -1) {
      validationClass += " validation-required";
    }
    let loadingClass = "";
    if (this.state.loading) {
      loadingClass = "loading";
    }

    return (
      <div className={`input-wrapper date-input ${validationClass} ${loadingClass} ${this.props.className || ""}`} ref={this.setWrapperRef}>
        {this.props.label ? (
          <label htmlFor={this.props.id}>
            {this.props.label} {this.renderTooltipIcon()}
            {validationClass && validationClass.includes("validation-required") && <small className="required">*</small>}
          </label>
        ) : null}
        <div className="input-container">
          <input
            disabled={this.state.disabled}
            ref={this.inputRef}
            onChange={this.onChange}
            onClick={this.toggleCalendar}
            type={inputType}
            placeholder={this.props.placeholder}
            name={this.props.name}
            value={value}
            autoComplete="off"
            data-dateFormat={this.state?.dateFormat}
          />

          {this.renderCalendar()}
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }
}

DateInputDeprecated.contextType = FormContext;
export default DateInputDeprecated;

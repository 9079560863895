import React, { ReactElement, useState } from "react";

import { useSelector } from "react-redux";
import { CopilotState } from "../../../../redux/slices/copilotSlice";
import { CombinedState } from "../../../../redux/store/state";

import { Card, CardBody, CardHeader } from "@adeccoux/tag-ds";

import { toLiteral } from "../../../../helper/locale-utils";

import CopilotAction from "./components/copilot-action";

import "./recruiter-copilot.scss";

/**
 * Component that renders the recruiter copilot
 */
const RecruiterCopilot: React.FC<any> = (): ReactElement => {
  const copilotState: CopilotState = useSelector((state: CombinedState) => state.copilot);

  const { contextActions } = copilotState;

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div id="recruiter-copilot" className="tag-ds">
      {dialogOpen && (
        <Card id="copilot-dialog" onMouseLeave={() => setDialogOpen(false)}>
          <CardHeader>
            <h5>{toLiteral({ id: "Hi! What can i help you with ?" })}</h5>
          </CardHeader>
          <CardBody>
            {contextActions?.map((contextAction) => (
              <CopilotAction key={contextAction?.id} setDialogOpen={setDialogOpen} contextAction={contextAction} />
            ))}
          </CardBody>
        </Card>
      )}
      <button type="button" id="copilot-bubble" onMouseEnter={() => setDialogOpen(true)}>
        <span className="material-icons">auto_awesome</span>
      </button>
    </div>
  );
};

export default RecruiterCopilot;

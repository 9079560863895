import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as Api from "../../../api";

import { CombinedState } from "../../../redux/store/state";
import { NavigationState } from "../../../redux/slices/navigationSlice";

import { getFeatureConfigurationIsEnabled } from "../../../helper/feature-configuration-helper";
import { _env } from "../../../config-env";

import Routes from "../router/routes";
import InternalSidebar from "../sidebar/internal-sidebar";
import UserFeedbackModal from "../components/user-feedback/user-feedback-modal";
import Header from "../header/internal-header";
import Footer from "../footer/footer";
import { Container } from "../../../constants";
import store from "../../../redux/store";
import { isSuperAdmin } from "../../../helper/role-helper";
import { toLiteral } from "../../../helper/locale-utils";
import MaintenanceMode from "../../recruitment/processes/components/maintenance-mode";
import Notification, { NotificationHeader } from "@adeccoux/tag-ds/notification";
import RecruiterCopilot from "../components/recruiter-copilot/recruiter-copilot";

/**
 * Component that renders the internal page
 */
const InternalContainer: React.FC<any> = (props): ReactElement => {
  const navigationState: NavigationState = useSelector((state: CombinedState) => state.navigation);

  const currentRoute = navigationState.currentRoute;
  const userFeedbackFC = getFeatureConfigurationIsEnabled("General Settings", "user-feedback");

  const [sidebar, setSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [userFeedback, setUserFeedback] = useState(false);
  const [userFeedbackClosed, setUserFeedbackClosed] = useState(false);
  const [showMaintenance, setShowMaintenance] = useState(false);

  const isMaintenanceMode = getFeatureConfigurationIsEnabled("General Settings", "recruiter-maintenance-mode");
  const tenantId = store.getState().tenant?.selectedTenant?.id;

  const recruiterCopilot = getFeatureConfigurationIsEnabled("General Settings", "recruiter-copilot");

  // if visitor is comming using external token we hide feedback modal
  const external = store?.getState()?.auth?.external || null;

  useEffect(() => {
    setSidebar(currentRoute?.id !== "LOGIN" && currentRoute?.id !== "LOGIN_TOKEN");
    setShowHeader(currentRoute?.id !== "LOGIN" && currentRoute?.id !== "LOGIN_TOKEN");
    if (currentRoute?.id !== "LOGIN" && currentRoute?.id !== "LOGIN_TOKEN" && userFeedbackFC && !userFeedbackClosed && !external) fetchUserFeedback();
    else setUserFeedback(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute, userFeedbackFC]);

  useEffect(() => addHotjarTracker(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv="), []);

  useEffect(() => {
    setShowMaintenance(isMaintenanceMode && isSuperAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, isMaintenanceMode]);

  const addHotjarTracker = (h: any, o: any, t: any, j: any) => {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: _env.HOTJAR_ID || 3535564, hjsv: 6 };
    let a = o.getElementsByTagName("head")[0];
    let r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  };

  async function fetchUserFeedback() {
    try {
      const userFeedbackResponse = await Api.getUserFeedback();
      if (!userFeedbackResponse) setUserFeedback(true);
    } catch (error) {
      if ((error as any)?.message === "EntityNotFoundError") setUserFeedback(true);
    }
  }

  function closeFeedback(close: boolean) {
    setUserFeedback(close);
    setUserFeedbackClosed(true);
  }

  return (
    <div id="internal-container">
      {sidebar && !currentRoute?.fullSizeView && <InternalSidebar />}
      <div id="app-content" className={external ? "no-sidebar" : ""}>
        {showMaintenance && (
          <Notification renderAsPortal={true} data-testid="notification" show warning>
            <NotificationHeader>{toLiteral({ id: "You are under maintenance mode. Remember to turn it off when the maintenance has finished." })}</NotificationHeader>
          </Notification>
        )}
        {showHeader && !currentRoute?.fullSizeView && <Header />}
        {isMaintenanceMode && !isSuperAdmin() ? <MaintenanceMode /> : <Routes onShowSnackbar={props.onShowSnackbar} getAppInsights={props.getAppInsights} />}
        <Footer container={Container.internal} />
      </div>
      {userFeedback && <UserFeedbackModal userFeedback={userFeedback} setUserFeedback={closeFeedback} />}
      {recruiterCopilot && <RecruiterCopilot />}
    </div>
  );
};

export default InternalContainer;

import { STATUS_JD } from "../components/candidate-sourcing/candidate-sourcing-constants";
import { hasAnyJobSchedulePublished, hasAnyJobScheduleScheduled, hasJobScheduleEnded } from "../components/candidate-sourcing/candidate-sourcing-service";
import { getFeatureConfigurationIsEnabled } from "./feature-configuration-helper";
import { isDefined } from "./object-helper";
import { onlyAdminsCanSeeData } from "./role-helper";

export interface IScheduleJD {
  id: string;
  startDate: number | null;
  endDate: number | null;
  customFields: any;
  boardId?: string;
  statusId?: number;
  cost?: number;
  lastUpdateInfo?: VonqUpdateInfo;
  name?: string;
}

export interface VonqUpdateInfo {
  postId: string;
  statusCode: number;
  statusMessage: string;
}

export enum VIRTUAL_STATUS_JD {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  PUBLISH = "ONLINE",
  OFFLINE = "OFFLINE",
  CLOSING = "CLOSING",
  CLOSED = "CLOSED",
  ARCHIVED = "ARCHIVED",
}

export enum SOURCES {
  recruitmentConnect = "RC",
  extarnalSource = "External source",
}
/**
 *
 * @param status Status of JD
 * @param schedules Array with all schedules, internal, external, multiposting...
 * @returns String status from virtual status JD
 */
export function generateStatus(status: string = "", schedules: any[] = []) {
  const _status = status.toUpperCase();
  if (_status === STATUS_JD.ACTIVE) {
    if (hasAnyJobSchedulePublished(schedules)) {
      // One in publish
      return VIRTUAL_STATUS_JD.PUBLISH;
    } else if (hasAnyJobScheduleScheduled(schedules)) {
      // One in schedule
      return VIRTUAL_STATUS_JD.SCHEDULED;
    } else if (hasJobScheduleEnded(schedules)) {
      return VIRTUAL_STATUS_JD.OFFLINE;
    } else {
      // All in draft
      return VIRTUAL_STATUS_JD.DRAFT;
    }
  } else if (_status === STATUS_JD.CLOSED) {
    if (hasAnyJobSchedulePublished(schedules)) {
      return VIRTUAL_STATUS_JD.CLOSING;
    } else {
      return VIRTUAL_STATUS_JD.CLOSED;
    }
  } else if (_status === STATUS_JD.ARCHIVED) {
    return VIRTUAL_STATUS_JD.ARCHIVED;
  } else {
    console.warn("Case not valid");
    return "";
  }
}

/* Show description to the info tooltip */
export function getDescriptionFromStatus(status: string): string {
  let desc: string;
  switch (status) {
    case STATUS_JD.ACTIVE: {
      desc = "The job description is ready to edit, publish or use in the services.";
      break;
    }
    case STATUS_JD.CLOSED: {
      desc = "The job description is closed, no more publications is allowed.";
      break;
    }
    case STATUS_JD.ARCHIVED: {
      desc = "The job description is archive, no showed in the lists.";
      break;
    }
    default:
      desc = "";
      break;
  }
  return desc;
}

/* To count the number of characters inside of the JD Sections */
export function checkLengthJD(boardSelected: any, values: any) {
  let response = { errorMinLength: false, errorMaxLength: false };
  if (boardSelected && (boardSelected.descriptionMinLength > 0 || boardSelected.descriptionMaxLength > 0)) {
    // Count :)
    const sections: any[] = values.descriptionSections || [];
    const jdLength = sections.reduce((totalLength, section) => countLengthContentHTML(totalLength, section?.value), 0);
    // Check with the limits
    if (isDefined(boardSelected.descriptionMinLength) && boardSelected.descriptionMinLength > jdLength) {
      response.errorMinLength = true;
    } else if (isDefined(boardSelected.descriptionMaxLength) && boardSelected.descriptionMaxLength < jdLength) {
      response.errorMaxLength = true;
    }
  }
  return response;
}
function countLengthContentHTML(total: number, html: string): number {
  if (html) {
    // Create HTML Node
    const element = document.createElement("div");
    element.innerHTML = html.trim();
    // Count only the content
    total += element?.textContent?.length || 0;
  }
  return total;
}

function addCommonOptions(optionsArray: any[], type: string) {
  const enabledTenantBranches = getFeatureConfigurationIsEnabled("General Settings", "tenant-branches");
  if (!onlyAdminsCanSeeData()) {
    const allOption = type === "jd" ? { name: "allJobDescriptions", title: "All Job Descriptions" } : { name: "allJobPosts", title: "All Job Posts" };
    optionsArray.push(allOption);
  }
  if (enabledTenantBranches) optionsArray.push({ name: "currentBranch", title: `administrator.${type}.branches.current` });
}

export function getJobDescriptionCategoryOptions() {
  const jobDescriptionCategoryOptions = [{ name: "myJobDescriptions", title: "My Job Descriptions" }];
  addCommonOptions(jobDescriptionCategoryOptions, 'jd');
  return jobDescriptionCategoryOptions;
}

export function getJobPostsCategoryOptions() {
  const jobPostsCategoryOptions = [{ name: "myJobPosts", title: "My Job Posts" }];
  addCommonOptions(jobPostsCategoryOptions, 'jp');
  return jobPostsCategoryOptions;
}

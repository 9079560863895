import { CURRENT_LOCALE } from "../../helper/locale-utils";
import { createRef } from "react";
import { IStep } from "@adeccoux/tag-ds/stepper";
import { countryCodes, countryValues, getCountryLocaleName } from "../../constants/countryCodes";
import { DEFAULT_FOR_PERSONAL_TEMPLATE, DEFAULT_FOR_TEMPLATE, DEFAULT_FOR_SMART_RECOMMENDATION } from "../../constants";

export enum JobBoardPublisherIds {
  CDX = "10000000-0000-0000-0000-000000000001",
  Linkedin = "10000000-0000-0000-0000-000000000002",
  Vonq = "10000000-0000-0000-0000-000000000003",
}

export enum JobBoardPublisherTitles {
  Websites = "Websites",
  CDX = "CDX",
  Linkedin = "Linkedin",
  Vonq = "Vonq",
}

export enum STATUS_JD {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  ARCHIVED = "ARCHIVED",
}
export enum STATUS_WEB_JD_ID {
  DRAFT = 1,
  SCHEDULE = 2,
  FAILED = 3,
  PENDING = 4,
  PUBLISHED = 5,
  PAUSED = 6,
  UPDATING = 7,
  NOTUPDATED = 8,
  DELETING = 9,
  NOTDELETED = 10,
  ARCHIVED = 11,
}
export enum STATUS_WEB_JD {
  DRAFT = "DRAFT",
  SCHEDULE = "SCHEDULE",
  FAILED = "FAILED",
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  PAUSE = "PAUSED",
  UPDATING = "UPDATING",
  NOTUPDATED = "NOTUPDATED",
  DELETING = "DELETING",
  NOTDELETED = "NOTDELETED",
  ARCHIVED = "ENDED",
}
export enum STATUS_WEB_JD_MAPPED {
  DRAFT = "Draft",
  SCHEDULED = "Scheduled",
  FAILED = "Failed",
  PENDING = "Pending",
  RUNNING = "Running",
  PAUSED = "Paused",
  UPDATING = "Updating",
  NOTUPDATED = "Not Updated",
  DELETING = "Deleting",
  NOTDELETED = "Not Deleted",
  ENDED = "Ended",
}

export enum JD_DEFAULT_BOARD_ID {
  INTERNAL = "00000000-0000-0000-0000-000000000001",
  INTERNALAPPLYFLOW = "00000000-0000-0000-0000-000000000004",
  EXTERNAL = "00000000-0000-0000-0000-000000000002",
  VONQ = "00000000-0000-0000-0000-000000000003",
}

export enum VONQ_BOARDNAMES {
  SEEK = "SEEK",
}

export enum CYCLE_INSIGHT {
  PREVIOUS = 0,
  NEXT = 1,
}

export enum AVAILABLE_CHANNELS {
  INTERNAL = "INTERNAL",
  INTERNALAPPLYFLOW = "INTERNALAPPLYFLOW",
  EXTERNAL = "EXTERNAL",
  MULTIPOSTING = "MULTIPOSTING",
  VONQ = "VONQ",
}

export enum OVERWRITE_APPLY {
  JOBID = "{jobId}",
  JOBDESCRIPTIONID = "{jobDescriptionId}",
  JOBSCHEDULEID = "{jobScheduleId}",
}

export enum JD_TEMPLATES_FILTER {
  LOCAL = "local",
  GLOBAL = "global",
  USER = "user",
  ALL = "all",
}

export const JOVEO_TITLE_CART = "Joveo";

export const jobDescriptionsListColumns_DEPRECATED = [
  { title: "Status", columnKey: "status", jobFilterOptions: [], select: true },
  { title: "Client", columnKey: "client", sortable: true, jobFilterOptions: [] },
  { title: "Job", columnKey: "externalId", sortable: true, jobFilterOptions: [], displayed: false },
  { title: "Job Id", columnKey: "vacancyReferenceNumber", sortable: true, jobFilterOptions: [] },
  { title: "Job Title", columnKey: "externalJobTitle", sortable: true, jobFilterOptions: [] },
  { title: "Internal Job Title", columnKey: "jobTitle", jobFilterOptions: [], displayed: false },
  { title: "Created by", columnKey: "createdBy", sortable: true, jobFilterOptions: [], type: "object" },
  { title: "Created on", columnKey: "createdOn", sortable: true, jobFilterOptions: [] },
  { title: "Contact Name", columnKey: "hiringManager", sortable: true, jobFilterOptions: [] },
  { title: "Source", columnKey: "source", sortable: true, jobFilterOptions: [], displayed: false, select: true },
  { title: "Description", columnKey: "description" },
  { title: "Posts", columnKey: "jobPosts" },
  { title: "Actions", columnKey: "actions" },
];

export const jobDescriptionsListColumns = [
  { title: "", columnKey: "checkbox", sortable: false },
  { title: "Status", columnKey: "status", jobFilterOptions: [], select: true },
  { title: "Job", columnKey: "externalId", sortable: true, jobFilterOptions: [], displayed: false },
  { title: "Job Id", columnKey: "vacancyReferenceNumber", sortable: true, jobFilterOptions: [], displayed: false },
  { title: "Job Title", columnKey: "externalJobTitle", sortable: true, jobFilterOptions: [] },
  { title: "Internal Job Title", columnKey: "jobTitle", jobFilterOptions: [], displayed: false },
  { title: "Client", columnKey: "client", sortable: true, jobFilterOptions: [] },
  { title: "Contact Name", columnKey: "hiringManager", sortable: true, jobFilterOptions: [] },
  { title: "Created on", columnKey: "createdOn", sortable: true, jobFilterOptions: [], type: "date" },
  { title: "Created by", columnKey: "createdBy", sortable: true, jobFilterOptions: [], type: "object" },
  { title: "Source", columnKey: "source", sortable: true, jobFilterOptions: [], displayed: false, select: true },
  { title: "Description", columnKey: "description" },
  { title: "Posts", columnKey: "jobPosts" },
];

export const jobPostingListColumns_DEPRECATED = [
  { title: "Status", columnKey: "statusId", sortable: true, filterOptions: [] },
  { title: "Job Post Id", columnKey: "id", sortable: true, filterOptions: [], displayed: false },
  { title: "Branch", columnKey: "branchId", sortable: true, filterOptions: [], displayed: false },
  { title: "Channel", columnKey: "name", sortable: true, filterOptions: [] },
  { title: "Job", columnKey: "externalId", sortable: true, filterOptions: [], displayed: false },
  { title: "Job Id", columnKey: "vacancyReferenceNumber", sortable: true, filterOptions: [] },
  { title: "Job Title", columnKey: "externalJobTitle", sortable: true, filterOptions: [] },
  { title: "Client", columnKey: "clientName", sortable: true, filterOptions: [] },
  { title: "Budget", columnKey: "cost", sortable: true, filterOptions: [] },
  { title: "Start Date", columnKey: "startDate", sortable: true, filterOptions: [], type: "date" },
  { title: "End Date", columnKey: "endDate", sortable: true, filterOptions: [], type: "date" },
  { title: "Source", columnKey: "source", sortable: true, filterOptions: [], displayed: false },
  { title: "Ending Soon", columnKey: "endingSoon", sortable: true, filterOptions: [], displayed: false },
  { title: "Created by", columnKey: "createdBy", sortable: true, filterOptions: [], type: "object", displayed: false },
  { title: "Created on", columnKey: "createdAt", sortable: true, filterOptions: [], type: "date" },
  { title: "Actions", columnKey: "actions" },
];

export const jobPostingListColumns = [
  { title: "", columnKey: "checkbox", sortable: false },
  { title: "Status", columnKey: "statusId", sortable: true, filterOptions: [] },
  { title: "Job Post Id", columnKey: "id", sortable: true, filterOptions: [] },
  { title: "Branch", columnKey: "branchId", sortable: true, filterOptions: [], displayed: false },
  { title: "Job", columnKey: "externalId", sortable: true, filterOptions: [], displayed: false },
  { title: "Job Id", columnKey: "vacancyReferenceNumber", sortable: true, filterOptions: [], displayed: false },
  { title: "Job Title", columnKey: "externalJobTitle", sortable: true, filterOptions: [] },
  { title: "Start Date", columnKey: "startDate", sortable: true, filterOptions: [], type: "date" },
  { title: "End Date", columnKey: "endDate", sortable: true, filterOptions: [], type: "date" },
  { title: "Client", columnKey: "clientName", sortable: true, filterOptions: [] },
  { title: "Channel", columnKey: "name", sortable: true, filterOptions: [] },
  { title: "Budget", columnKey: "cost", sortable: false, filterOptions: [] },
  { title: "Created on", columnKey: "createdAt", sortable: true, filterOptions: [] },
  { title: "Source", columnKey: "source", sortable: true, filterOptions: [], displayed: false },
  { title: "Ending Soon", columnKey: "endingSoon", sortable: false, filterOptions: [], displayed: false },
  { title: "Created by", columnKey: "createdBy", sortable: true, filterOptions: [], type: "object", displayed: false },
];

export const jobPostingInJDListColumns_DEPRECATED = [
  { title: "Status", columnKey: "statusId", sortable: false, filterOptions: [] },
  { title: "Channel", columnKey: "name", sortable: false, filterOptions: [] },
  { title: "Start Date", columnKey: "startDate", sortable: false, filterOptions: [] },
  { title: "End Date", columnKey: "endDate", sortable: false, filterOptions: [] },
  { title: "Actions", columnKey: "actions" },
];
export const jobPostingInJDListColumns = [
  { title: "Status", columnKey: "statusId", sortable: false, filterOptions: [] },
  { title: "Start Date", columnKey: "startDate", sortable: false, filterOptions: [] },
  { title: "End Date", columnKey: "endDate", sortable: false, filterOptions: [] },
  { title: "Channel", columnKey: "name", sortable: false, filterOptions: [] },
  { title: "", columnKey: "actions" },
];

export const jobDescriptionsTemplatesListColumns = [
  { title: "Name", columnKey: "title", sortable: true, filterOptions: [] },
  { title: "Display language", columnKey: "displayLanguage", sortable: true, filterOptions: [] },
  { title: "Default for", columnKey: "defaultFor", sortable: true, filterOptions: [] },
  { title: "Reorder", columnKey: "sectionsReorder", sortable: true, filterOptions: [] },
  { title: "Adding", columnKey: "sectionsAdding", sortable: true, filterOptions: [] },
  { title: "Redefined name", columnKey: "predefinedNaming", sortable: true, filterOptions: [] },
  { title: "Category", columnKey: "categoryId", sortable: true, filterOptions: [], displayed: false },
  { title: "Created by", columnKey: "createdByUser", sortable: true, filterOptions: [], type: "object" },
  { title: "Created on", columnKey: "createdOn", sortable: true, filterOptions: [], type: "date" },
  { title: "Global", columnKey: "local", sortable: true, filterOptions: [] },
  { title: "Enable", columnKey: "enable", sortable: true, filterOptions: [] },
  { title: "Actions", columnKey: "actions" },
];

/* TABS */
export const jobDescriptionsListTabs = [
  { title: "All job descriptions", hash: "#all" },
  { title: "My branch job descriptions", hash: "#mybranch" },
];
export const jobDescriptionsButtons = [{ title: "Create new", action: "createNewJobDescription" }];
export const jobDescriptionsActions = [
  { title: "Duplicate", action: "duplicateElement", icon: "file_copy", conditions: [{ type: "count", value: 1 }] },
  {
    title: "Delete",
    action: "deleteElement",
    icon: "delete",
    conditions: [
      { type: "count", value: Infinity },
      { type: "arrayItem", field: "jobDescriptionSchedules.statusId", condition: "in", value: [STATUS_WEB_JD_ID.DRAFT, STATUS_WEB_JD_ID.SCHEDULE] },
    ],
  },
  {
    title: "Close",
    action: "closeElement",
    icon: "close",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "status", condition: "eq", value: STATUS_JD.ACTIVE },
      { type: "item", field: "jobDescriptionSchedules.length", condition: "gt", value: 0 },
      { type: "arrayItem", field: "jobDescriptionSchedules.statusId", condition: "hasn", value: [STATUS_WEB_JD_ID.DRAFT, STATUS_WEB_JD_ID.SCHEDULE] },
    ],
  },
  {
    title: "Archive",
    action: "archiveElement",
    icon: "archive",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "status", condition: "eq", value: STATUS_JD.CLOSED },
      { type: "item", field: "jobDescriptionSchedules.length", condition: "gt", value: 0 },
      { type: "arrayItem", field: "jobDescriptionSchedules.statusId", condition: "in", value: [STATUS_WEB_JD_ID.ARCHIVED] },
    ],
  },
  {
    title: "Copy apply url",
    action: "openURLModal",
    icon: "file_copy",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "applyUrl", condition: "nin", value: [null, undefined, ""] },
    ],
  },
  {
    title: "Repost",
    action: "repostJDElement",
    icon: "update",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "status", condition: "eq", value: STATUS_JD.ACTIVE },
    ],
  },
];

/* TABS POSTING */
export const jobPostingListTabs = [
  { title: "All job posts", hash: "#all" },
  { title: "My branch job posts", hash: "#mybranch" },
];

export const jobPostingActions = [
  {
    title: "Repost",
    action: "repostJDSElement",
    icon: "update",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "status", condition: "eq", value: STATUS_JD.ACTIVE },
    ],
  },
  {
    title: "Pause",
    action: "pauseJDSElement",
    icon: "pause",
    conditions: [
      { type: "count", value: -1 },
      { type: "item", field: "statusId", condition: "eq", value: STATUS_WEB_JD_ID.PUBLISHED },
    ],
  },
  {
    title: "Resume",
    action: "resumeJDSElement",
    icon: "play_arrow",
    conditions: [
      { type: "count", value: -1 },
      { type: "item", field: "statusId", condition: "eq", value: STATUS_WEB_JD_ID.PAUSED },
    ],
  },
  {
    title: "Delete",
    action: "deleteJDSElement",
    icon: "delete",
    conditions: [
      { type: "count", value: Infinity },
      { type: "item", field: "statusId", condition: "in", value: [STATUS_WEB_JD_ID.DRAFT, STATUS_WEB_JD_ID.SCHEDULE] },
    ],
  },
  {
    title: "Archive",
    action: "archiveJDSElement",
    icon: "archive",
    conditions: [
      { type: "count", value: 1 },
      {
        type: "item",
        field: "statusId",
        condition: "in",
        value: [STATUS_WEB_JD_ID.PUBLISHED, STATUS_WEB_JD_ID.PENDING, STATUS_WEB_JD_ID.UPDATING]
      },
    ],
  },
  {
    title: "Retry",
    action: "retryJDSElement",
    icon: "autorenew",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "statusId", condition: "in", value: [STATUS_WEB_JD_ID.NOTDELETED, STATUS_WEB_JD_ID.NOTUPDATED, STATUS_WEB_JD_ID.FAILED] },
    ],
  },
  // For accordion - change the name of the field of the board response (JDS)
];

export const applyFlowJobPostingActions = [
  {
    title: "Delete",
    action: "deleteJDSElement",
    icon: "delete",
    conditions: [
      { type: "count", value: Infinity },
      { type: "item", field: "statusId", condition: "in", value: [STATUS_WEB_JD_ID.DRAFT, STATUS_WEB_JD_ID.SCHEDULE] },
    ],
  },
  {
    title: "Archive",
    action: "archiveJDSElement",
    icon: "archive",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "statusId", condition: "in", value: [STATUS_WEB_JD_ID.PUBLISHED, STATUS_WEB_JD_ID.NOTUPDATED] },
    ],
  },
  {
    title: "Retry",
    action: "retryJDSElement",
    icon: "autorenew",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "statusId", condition: "in", value: [STATUS_WEB_JD_ID.FAILED, STATUS_WEB_JD_ID.NOTUPDATED] },
    ],
  },
  {
    title: "Retry",
    action: "archiveJDSElement",
    icon: "autorenew",
    conditions: [
      { type: "count", value: 1 },
      { type: "item", field: "statusId", condition: "eq", value: STATUS_WEB_JD_ID.NOTDELETED },
    ],
  },
];

/* EXTRA INFO PROPERTIES */
export const propertiesJDList = [
  { name: "vacancyReferenceNumber", title: "info.vacancyRefNumber" },
  { name: "status", title: "info.status" },
  { name: "status.description", title: "info.status.description" },
  { name: "branchId", title: "info.branchId" },
  { name: "externalId", title: "info.externalId" },
  { name: "source", title: "info.source" },
  { name: "displayLanguage", title: "info.language" },
  { name: "process", title: "info.process" },
  { name: "cobrand", title: "info.cobrand" },
  { name: "subbrand", title: "info.subbrand" },
];
export const propertiesJDPostingList = [
  { name: "dataVacancyReferenceNumber", title: "info.vacancyRefNumber" },
  { name: "status", title: "info.status" },
  { name: "status.description", title: "info.status.description" },
  { name: "branchId", title: "info.branchId" },
  { name: "jobDescriptionExternalId", title: "info.externalId" },
  { name: "source", title: "info.source" },
  // Missing display language
  { name: "processId", title: "info.process" },
  { name: "cobrandId", title: "info.cobrand" },
  { name: "subbrandId", title: "info.subbrand" },
];

/* FORM OPTIONS */
export const defaultForTemplate = [
  { label: "None", value: "" },
  { label: "Personal Template", value: DEFAULT_FOR_PERSONAL_TEMPLATE },
  { label: "Job Description Language", value: DEFAULT_FOR_TEMPLATE },
  { label: "Job Description Recommendation", value: DEFAULT_FOR_SMART_RECOMMENDATION },
];

export const currencyValues = [
  { label: "€", value: "EUR" },
  { label: "$", value: "USD" },
  { label: "£", value: "GBP" },
  { label: "Fr. chf", value: "CHF" },
];

export const salaryTimeScaleDefaultValues = [
  { label: "Day", value: "PERDAY" },
  { label: "Hour", value: "PERHOUR" },
  { label: "Week", value: "PERWEEK" },
  { label: "Month", value: "PERMONTH" },
  { label: "Year", value: "PERYEAR" },
];

export const allLabels = [...currencyValues, ...salaryTimeScaleDefaultValues];

export const getNiceValue = (value: string) => {
  const matchingOption = allLabels.find((a) => a.value === value);
  return matchingOption ? matchingOption.label : value;
};

export const renderCountryNameTranslated = (_nameField: string, value: string) => {
  const matches = countryCodes.find((a) => a.value === value);
  const translatedValue = matches ? getCountryLocaleName(matches, CURRENT_LOCALE) : value;
  return translatedValue;
};
export const getCountryValue = (value: string) => {
  const matchingOption = countryValues.find((a) => a.value === value);
  return matchingOption ? matchingOption.label : value;
};

export const OPTIONSCONTACTINFO = [
  { label: "reference", value: "dataVacancyReferenceNumber", set: false },
  { label: "email", value: "dataContactEmail", set: true },
  { label: "name", value: "dataContactName", set: true },
  { label: "phone", value: "dataContactPhone", set: true },
];
export const PLACEHOLDERCLIENTLOGO = {
  value: "",
  label: "Select an option",
};

export const JD_HEADER_STEPS: IStep[] = [
  {
    name: "Basic details",
    detail: "",
    status: "success",
  },
  {
    name: "Write description",
    detail: "",
    status: "default",
  },
  {
    name: "Schedule and post",
    detail: "",
    status: "default",
  },
];

export const JD_SUB_STEPS_1 = [
  {
    index: 1,
    title: "Job information",
  },
  {
    index: 2,
    title: "Contract specifications",
  },
  {
    index: 3,
    title: "Client information",
  },
  {
    index: 4,
    title: "Location details",
  },
  {
    index: 5,
    title: "Contact details",
  },
];
export const JD_SUB_STEPS_2 = [
  {
    index: 6,
    title: "1. Template configuration",
  },
  {
    index: 7,
    title: "2. Sections",
  },
];

export const initStepRefs = () => {
  let stepRefs: any = {};
  JD_SUB_STEPS_1.forEach((step) => {
    stepRefs[step.index] = createRef();
  });
  JD_SUB_STEPS_2.forEach((step) => {
    stepRefs[step.index] = createRef();
  });
  return stepRefs;
};

export enum VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP {
  DRAFT = "The Job Description hasn’t been submitted for posting to the board.",
  SCHEDULED = "The Job Description is going to be submitted for posting to the board on the scheduled Start Date.",
  PENDING = "The Job Description has been submitted for posting to the board. Not posted yet.",
  FAILED1 = "The Job Description has been rejected for posting to the board with a reason: ",
  FAILED2 = ". The reason according to the reported error message needs to be resolved and the posting needs to be retried.",
  RUNNING = "The Job Description has successfully been posted to the board or the updates have been successfully posted.",
  UPDATING = "The Job Description has been submitted for updating on the board. Not posted yet.",
  NOTUPDATED1 = "The Job Description has been rejected for updating on the board with a reason: ",
  NOTUPDATED2 = ". The Job Description stays posted with no updates on the board. The reason according to the reported error message needs to be resolved and the posting needs to be retried.",
  DELETING = "The Job Description has been submitted for deleting on the board. Not posted yet.",
  NOTDELETED1 = "The Job Description has been rejected for deleting on the board with a reason: ",
  NOTEDELETED2 = ". The Job Description stays posted with no updates on the board. The reason according to the reported error message needs to be resolved and the posting needs to be retried.",
  ENDED = "The Job Description has been successfully deleted from the Job Board.",
}

export const INFO_JD_PARAM = "jd_Id";

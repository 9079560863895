import { _env } from "../../../../config-env";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { resetCopilotContextActions } from "../../../../redux/slices/copilotSlice";

import { logout } from "../../../../helper/auth-helper";

import { NavigationState, setNavigation } from "../../../../redux/slices/navigationSlice";

import { getCurrentRoute, NavItem } from "../../../../utilities/navigation";
import { userIsAuth } from "../../../../utilities/authentication";

import Loading from "../../loading/loading";
import { CombinedState } from "../../../../redux/store/state";

/**
 * Component that handle the auth context
 */
const NavigationHandler: React.FC<any> = (props): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const navigationState: NavigationState = useSelector((state: CombinedState) => state.navigation);
  const currentRoute = navigationState.currentRoute;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    const currentRoute: NavItem = getCurrentRoute(location.pathname);
    dispatch(setNavigation(currentRoute, location.pathname));
    if (window.location.href.startsWith("http:") && _env.REACT_APP_ENV_PREFIX !== "a") {
      let currentLocation = window.location.href;
      currentLocation = currentLocation.replace("http:", "https:");
      if (window && typeof window.open === "function") {
        window.open(currentLocation, "_self");
      }
    }
    if (!currentRoute) {
      history.push(`/not-found?url=${window.location.href}`);
      return;
    }
    if (!currentRoute.public && !userIsAuth() && currentRoute.id !== "LOGIN" && currentRoute.id !== "LOGIN_TOKEN") {
      if (location.search) localStorage.setItem("location-search-params", location.search);
      logout(history, "login.error", dispatch, () => {
        console.error("Login error");
      });
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    dispatch(resetCopilotContextActions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute?.id]);

  return <>{isLoading ? <Loading id="navigation-handler" /> : props.children}</>;
};

export default NavigationHandler;

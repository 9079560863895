import React, { ReactElement } from "react";
import ReactDOM from "react-dom";

/**
 * Component that renders the app loading
 */

export const addCopilotLoading = (id: string): void => {
  const container = document.getElementById(id);
  if (container) {
    container.parentNode?.removeChild(container);
  }

  const newContainer = document.createElement("div");
  newContainer.id = id;
  document.body.appendChild(newContainer);
  ReactDOM.render(<CopilotLoading id={id} />, newContainer);
};

export const removeCopilotLoading = (id: string): void => {
  const container = document.getElementById(id);
  if (container) {
    ReactDOM.unmountComponentAtNode(container);
    container.parentNode?.removeChild(container);
  }
};

const CopilotLoading: React.FC<any> = (props: { id: string }): ReactElement => {
  return <div id={props?.id} className="magic-generated"></div>;
};

export default CopilotLoading;

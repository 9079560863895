import _default from "./default.json";
import adeccoGermany from "./adeccoGermany.json";
import proservGermany from "./proservGermany.json";
import adeccoSwitzerland from "./adeccoSwitzerland.json";
import BAndCSwitzerland from "./bandcSwitzerland.json";
import adeccoItaly from "./adeccoItaly.json";
import lhhItaly from "./lhhItaly.json";
import adeccoAustralia from "./adeccoAustralia.json";

const allFields = [
  {
    name: "default",
    tenantId: "default",
    fields: _default,
  },
  {
    name: "adecco germany",
    tenantId: "PLRwT3Nabcg7g6LMzxZx",
    fields: adeccoGermany,
  },
  {
    name: "adecco abs germany",
    tenantId: "EtvMBWOQaXheIs9U0UIQ",
    fields: adeccoGermany,
  },
  {
    name: "adecco ascs germany",
    tenantId: "h1328fdMTRUXxVYWcM8A",
    fields: adeccoGermany,
  },
  {
    name: "lhh germany",
    tenantId: "9tjp3HBCpYtaqgLVH7cH",
    fields: adeccoGermany,
  },
  {
    name: "dis consulting germany",
    tenantId: "cuY9TEfwuQwbECCnnfN5",
    fields: adeccoGermany,
  },
  {
    name: "dis ag germany",
    tenantId: "cAukrpzneH97AXbTsW6q",
    fields: adeccoGermany,
  },
  {
    name: "proserv germany",
    tenantId: "9Y6Jg4iHQ2mq1EdXpnDL",
    fields: proservGermany,
  },
  {
    name: "adecco switzerland",
    tenantId: "UXNZeTmQCVkTHPmfxQDc",
    fields: adeccoSwitzerland,
  },
  {
    name: "adecco global test",
    tenantId: "ZTTspWjH8UU4fcysQmMV",
    fields: adeccoSwitzerland,
  },
  {
    name: "FOCORE",
    tenantId: "mTUN8pqh2wCgSA9EBwYD",
    fields: adeccoSwitzerland,
  },
  {
    name: "B&C switzerland",
    tenantId: "C23TmlrlMD6HcDq48oCl",
    fields: BAndCSwitzerland,
  },
  {
    name: "adecco italy",
    tenantId: "RnMfkC9Y7CTubjnuq4tZ",
    fields: adeccoItaly,
  },
  {
    name: "LHH italy",
    tenantId: "sVV6hwe3kmSZuefb7CAr",
    fields: lhhItaly,
  },
  {
    name: "B&C italy",
    tenantId: "3PGHf28f6507o6CYVIU2",
    fields: lhhItaly,
  },
  {
    name: "SP italy",
    tenantId: "X1SJm6r98YaW7068bNr0",
    fields: lhhItaly,
  },
  {
    name: "adecco Australia",
    tenantId: "qMA7SXSS96svUjTNb4cR",
    fields: adeccoAustralia,
  },
];
export default allFields;

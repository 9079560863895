import { FOCORE_RECEIVED_BRAND, FOCORE_RECEIVED_COUNTRY, FOCORE_SEND_BRAND, FOCORE_SEND_COUNTRY, FOCORE_TENANT } from "../constants";
import { Sources } from "../types";
import { TenantStorage } from "./tenant-storage";

export function getQueryStringValue(key: string) {
  const regex = new RegExp(
    `^(?:.*[&\\?]${encodeURIComponent(key).replace(
      // eslint-disable-next-line no-useless-escape
      /[\.\+\*]/g,
      "\\$&"
    )}(?:\\=([^&]*))?)?.*$`,
    "i"
  );
  return decodeURIComponent(window.location.search.replace(regex, "$1"));
}

export function getURLSearchParams(location: any, keys: string[] = []): any {
  const urlParams: any = {};
  keys.forEach((key) => {
    urlParams[key] = new URLSearchParams(location.search).get(key) || null;
  });
  return urlParams;
}

export function getBranch(branches: any, branchRemoteId?: string | null) {
  let newBranch: any;
  if (!branches) return undefined;
  if (branchRemoteId) {
    newBranch = branches.find((branch: any) => branch.remoteId === branchRemoteId);
  } else {
    newBranch = branches.find((branch: any) => branch.branchName === "Default");
  }
  if (!newBranch) newBranch = branches[0];
  return newBranch;
}

export function getCountry(country: string | null) {
  // This nasty solution is because INFO can not create custom COUNTRY
  if (country === FOCORE_RECEIVED_COUNTRY) {
    console.info("COUNTRY CHANGED TO");
    return FOCORE_SEND_COUNTRY;
  } else {
    return country;
  }
}
export function getBrand(country: string | null, brand: string | null) {
  // This nasty solution is because INFO can not create BRAND FOCORE
  if (country === FOCORE_SEND_COUNTRY && brand === FOCORE_RECEIVED_BRAND) {
    console.info("BRAND CHANGED TO");
    return FOCORE_SEND_BRAND;
  } else {
    return brand;
  }
}
export function generateSource(source: string, tenantId: number = 0) {
  if (source === Sources.infocore && tenantId !== FOCORE_TENANT) {
    return Sources.infoeurope;
  }
  return source;
}

export function storageAndGetTenant(tenant: any, tenantStorage: TenantStorage, remoteId?: string): any {
  const branch = tenant.branches && tenant.branches.length > 0 ? getBranch(tenant.branches, remoteId) : undefined;
  tenantStorage.setTenant({
    id: tenant.id,
    alias: tenant.alias,
    branchId: branch ? branch.branchId : "",
    regionId: branch ? branch.regionId : "",
    zoneId: branch ? branch.zoneId : "",
  });
  return branch;
}

import { generateAnalysis, generateQuestions } from "../../../../api";

export enum CopilotActionID {
  QuestionGenerator = "GENERATOR_QUESTION",
  AnalyzeStatus = "ANALYZE_STATUS",
}

interface CopilotAction {
  id: CopilotActionID;
  label: string;
  apiCall: Function;
}

export const COPILOT_ACTION: CopilotAction[] = [
  { id: CopilotActionID.QuestionGenerator, label: "Generate some questions", apiCall: generateQuestions },
  { id: CopilotActionID.AnalyzeStatus, label: "Analyze Candidate status", apiCall: generateAnalysis },
];

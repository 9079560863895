import React, { ReactElement } from "react";

import { toLiteral } from "../../../../../helper/locale-utils";
import { addNotification } from "../../../../shared/notifications/notifications-service";

import { addCopilotLoading, removeCopilotLoading } from "./copilot-loading";
import { ContextAction } from "../../../../../redux/slices/copilotSlice";

interface CopilotActionProps {
  key: string;
  setDialogOpen: Function;
  contextAction: ContextAction;
}

/**
 * Component that renders the recruiter copilot
 */
const CopilotAction: React.FC<any> = (props: CopilotActionProps): ReactElement => {
  const { setDialogOpen, contextAction, key } = props;

  const { apiCall, id, callbackFunction, label, apiParams } = contextAction;

  async function triggerAction() {
    setDialogOpen(false);
    if (!apiCall || !id) return;
    try {
      addCopilotLoading(id);
      const response = await apiCall(apiParams);
      if (callbackFunction) await callbackFunction(response);
    } catch (e) {
      addNotification({ type: "error", content: toLiteral({ id: "Sorry there was an error" }), timer: 3 });
    }
    removeCopilotLoading(id);
  }

  return (
    <div className="copilot-action" key={key} onClick={() => triggerAction()}>
      {toLiteral({ id: label })}
      <hr className="mb1 mt1" />
    </div>
  );
};

export default CopilotAction;

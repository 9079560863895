import { FormContext } from "./form";
import BaseInput from "./base-input";
import { toLiteral } from "../../../helper/locale-utils";
import { getMultilanguageInputName, renderMutilanguageDefaultHiddenInput } from "./multilanguage/multi-language-service";

class TextArea extends BaseInput {
  type = "TextArea";
  //Rest of the functions and properties exist in baseInput, who's shared accross all form inputs.
  //They can be overrided using the same name and params type.

  /**
   * Renders the hidden inputs that will store the values for the lagunages that are not selected
   * if the form has available languages, defined in the props of "MultiLanguage" component, it will render hidden inputs to store the alternative language values
   */

  render() {
    this.processCSSClasses();
    return (
      <div className={`input-wrapper ${this.validationClass} ${this.loadingClass} ${this.props.className || ""}`}>
        {this.renderInputLabel()}
        {renderMutilanguageDefaultHiddenInput(this)}
        <textarea
          ref={this.inputRef}
          disabled={this.state.disabled}
          readOnly={this.state.readOnly}
          onChange={this.onChange}
          placeholder={toLiteral({ id: this.props.placeholder })}
          name={getMultilanguageInputName(this)}
          value={this.state.value ? this.state.value : ""}
          rows={this.props.rows}
          cols={this.props.cols}
          id={this.props.id}
          data-testid={this.props.id}
        ></textarea>

        {this.props.maxLength && <span className="textarea-count">{`${this.state.value?.length || "0"}/${this.props.maxLength}`}</span>}

        {this.renderErrorMessage()}
      </div>
    );
  }
}

TextArea.contextType = FormContext;
export default TextArea;

import React, { ReactElement } from "react";
import Loader from "@adeccoux/tag-ds/loader";
import ReactDOM from "react-dom";
import { getTagDSTheme } from "../../../utilities/theme";
import store from "../../../redux/store";

/**
 * Component that renders the app loading
 */

export const addLoading = (id: string, title?: string): void => {
  const container = document.getElementById(id);
  if (container) {
    container.parentNode?.removeChild(container);
  }

  const newContainer = document.createElement("div");
  newContainer.id = id;
  document.body.appendChild(newContainer);
  ReactDOM.render(<Loading title={title} id={id} />, newContainer);
};

export const removeLoading = (id: string): void => {
  const container = document.getElementById(id);
  if (container) {
    ReactDOM.unmountComponentAtNode(container);
    container.parentNode?.removeChild(container);
  }
};

const Loading: React.FC<any> = (props: { title?: string; id: string }): ReactElement => {
  return ReactDOM.createPortal(
    <div id={props?.id} data-testid={props?.id} className="loading-bar-layout">
      <Loader className={`tag-ds ${getTagDSTheme(store?.getState()?.tenant?.selectedTenant?.theme)} loading-main`} title={props?.title} spinner />
    </div>,
    document.body
  );
};

export default Loading;
